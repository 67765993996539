import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {
  UserProfileNested,
  UserRoleEmbed,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import ExternalRedirect from 'components/ExternalRedirect';
import Loading from 'components/Loading';
import SidebarRight from 'components/SidebarRight';
import {useAuthentication} from 'features/useAuthentication';
import {List} from 'immutable';
import {PageWrapper} from 'letrus-ui';
import {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {AuthRoutes, PublicRoutes} from 'routes';
import {
  clearState,
  isAdmin,
  isAuthed,
  isLoadingLogout,
  logoutUserRequest,
} from 'store/reducers/authentication';
import {
  fetchFeatureFlagRequest,
  getFeatureFlags,
} from 'store/reducers/featureFlags';
import {fetchTermsRequest, getTerms} from 'store/reducers/globalConfiguration';
import {
  fetchNotificationsRequest,
  getNotifications,
  getUnseenNotificationCount,
} from 'store/reducers/notifications';
import {
  fetchCurrentUserRequest,
  getIsStudent,
  getUser,
  getUserProfile,
  getUserRoles,
  isLoadingCurrentUser,
  isReviewer,
  isTeacher,
  logoutSuccess,
} from 'store/reducers/user';
import {ApplicationState} from 'store/rootReducer';
import setupUserHubspotConversation from 'utils/functions/hubspot/setupUserHubspotConversation';
import isImmutableObjectEmpty from 'utils/functions/isImmutableObjectEmpty';
import {useMobile, usePrevious} from 'utils/hooks';
import useTrackEvent from 'utils/hooks/useTrackEvent';
import {getCookie} from 'utils/types/cookies';

export type UserRole = 'admin' | 'staff' | 'nonAdmin';

type FeatureFlags = {
  [K in keyof any]?: 'on' | 'off';
};

interface OwnProps {
  Component: React.FC<any>;
  path: string;
  exact?: boolean;
  withPageWrapper?: boolean;
}

interface StateProps {
  isDevAuthStateValid: boolean;
  isAdmin: boolean;
  isStudent: boolean;
  isTeacher: boolean;
  isReviewer: boolean;
  terms: List<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>;
  isLoadingLogout: boolean;
  user: ImmutableMap<LetrusApi.User>;
  isLoadingCurrentUser: boolean;
  unseenNotificationCount: any;
  notifications: any;
  featureFlags?: ImmutableMap<FeatureFlags>;
  userRole: ImmutableMap<UserRoleEmbed>;
  userProfile: ImmutableMap<UserProfileNested>;
}

interface DispatchProps {
  fetchCurrentUserRequest: typeof fetchCurrentUserRequest;
  logoutUserRequest: typeof logoutUserRequest;
  fetchTermsRequest: typeof fetchTermsRequest;
  logoutSuccess: typeof logoutSuccess;
  clearState: typeof clearState;
  fetchNotificationsRequest: typeof fetchNotificationsRequest;
  fetchFeatureFlagRequest: typeof fetchFeatureFlagRequest;
}

type AuthRouteProps = OwnProps & StateProps & DispatchProps;

function NewAuthRoute({
  Component,
  path,
  exact = false,
  isDevAuthStateValid,
  logoutSuccess,
  terms,
  user,
  fetchCurrentUserRequest,
  fetchTermsRequest,
  isLoadingCurrentUser,
  isStudent,
  logoutUserRequest,
  isAdmin,
  isLoadingLogout,
  isTeacher,
  isReviewer,
  unseenNotificationCount,
  notifications,
  withPageWrapper,
  clearState,
  fetchNotificationsRequest,
  fetchFeatureFlagRequest,
  featureFlags,
  userRole,
  userProfile,
}: AuthRouteProps): JSX.Element {
  const {getRedirectUrl, areTermsUpdated, isFirstAccess} = useAuthentication({
    user,
    profile: userProfile,
    terms,
  });
  const history = useHistory();
  const {isMobile} = useMobile();
  const {trackEvent} = useTrackEvent();

  const shouldShowLibraryOnMenu = featureFlags?.get('teacher_library') === 'on';
  const shouldShowSocioEmotionalOnMenu =
    featureFlags?.get('teacher_socioemotional_dashboard') === 'on';

  const {pathname} = useLocation();
  const isCurrentLocationFirstAccess = pathname.includes(
    AuthRoutes.firstAccess,
  );
  const isCurrentLocationAcceptTerms = pathname.includes(
    AuthRoutes.acceptTerms,
  );

  const authToken = getCookie('userId');
  const isProdEnviroment = process.env.NODE_ENV === 'production';
  const isDevEnviroment = process.env.NODE_ENV !== 'production';

  const hasAccessedAsNonTeacher = usePrevious(isReviewer || isStudent);
  const urlToRedirect = useRef<string | null>(null);

  const isAuthenticated =
    (isProdEnviroment && authToken) || (isDevEnviroment && isDevAuthStateValid);

  useEffect(() => {
    if (isAuthenticated) {
      fetchNotificationsRequest();
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      const unseenNotificationText = `(${
        unseenNotificationCount > 99 ? '99+' : unseenNotificationCount
      })`;

      document.title = `${
        unseenNotificationCount ? unseenNotificationText : ''
      } Letrus`;
    }
  }, [pathname, notifications]);

  useEffect(() => {
    if (
      !isLoadingCurrentUser &&
      !isImmutableObjectEmpty(user) &&
      process.env.NODE_ENV === 'production'
    ) {
      // If external API methods are already available, use them.
      if (window.HubSpotConversations) {
        setupUserHubspotConversation(user);
      } else {
        // Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        // These callbacks will be called once the external API has been initialized.
        window.hsConversationsOnReady = [
          () => setupUserHubspotConversation(user),
        ];
      }
    }
  }, [isLoadingCurrentUser]);

  if (window.hsConversationsSettings) {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchTermsRequest();
      fetchCurrentUserRequest();

      const schoolNetworkId = userRole?.get('school_network_id');

      fetchFeatureFlagRequest({
        flagKey: 'teacher_library',
        entityId: `${user.get('id')}`,
        entityContext: {
          network_id: String(schoolNetworkId),
        },
      });

      fetchFeatureFlagRequest({
        flagKey: 'teacher_socioemotional_dashboard',
        entityId: `${user.get('id')}`,
        entityContext: {
          network_id: String(schoolNetworkId),
        },
      });

      fetchFeatureFlagRequest({
        flagKey: 'pilot_homepage',
        entityId: `${user.get('id')}`,
        entityContext: {
          school_id: String(userRole?.get('school')),
          network_id: userRole?.get('school_network_id')
            ? String(userRole?.get('school_network_id'))
            : undefined,
        },
      });
    }
  }, []);

  function clearStoreState() {
    clearState();
    logoutSuccess();
  }

  if (user.size && !isTeacher && !isAdmin) {
    logoutUserRequest();
    clearStoreState();
    urlToRedirect.current = getRedirectUrl({isStudent, isReviewer});
  }

  // Block access to private routes and redirect to login if not authenticated
  if (!isAuthenticated) {
    // Redirects to external link if user has already accessed as a non-teacher
    if (hasAccessedAsNonTeacher && urlToRedirect.current) {
      return <ExternalRedirect to={urlToRedirect.current} />;
    }

    clearStoreState();

    return <Redirect to={{pathname: PublicRoutes.login}} />;
  }

  // Enter in loading state when required data is not ready or is logging out the current user
  if (!user.size || !terms.size || isLoadingLogout || isLoadingCurrentUser) {
    return <Loading show />;
  }

  // Ask user to accept terms if not already accepted or not updated
  if (
    terms.size &&
    user.size &&
    isAuthenticated &&
    !areTermsUpdated &&
    !isCurrentLocationAcceptTerms &&
    !isLoadingCurrentUser
  ) {
    return (
      <Redirect
        to={{
          pathname: AuthRoutes.acceptTerms,
          search: `?next=${pathname}`,
        }}
      />
    );
  }

  // Redirect user to data confirmation page on first access
  if (
    user.size &&
    terms.size &&
    isFirstAccess &&
    isAuthenticated &&
    areTermsUpdated &&
    !isLoadingCurrentUser &&
    !isCurrentLocationFirstAccess
  ) {
    return <Redirect to={AuthRoutes.firstAccess} />;
  }

  function mountNewSidebarItems(
    label: string,
    icon: [string, string],
    active: boolean,
    onClick: () => void,
  ) {
    return {
      label,
      icon: {icon},
      active,
      onClick,
    };
  }

  function onClickLearningPath() {
    trackEvent('sidebar_click_learning_path');
    history.push('/atividades/regulares');
  }

  const topSidebarItems = [
    mountNewSidebarItems(
      'Página Inicial',
      ['fad', 'house-chimney'],
      pathname === '/',
      () => history.push('/'),
    ),
    mountNewSidebarItems(
      'Trilhas',
      ['fad', 'train-track'],
      pathname === '/atividades/regulares',
      onClickLearningPath,
    ),
    mountNewSidebarItems(
      'Evolução',
      ['fad', 'chart-line'],
      pathname === '/evolucao',
      () => history.push(`/evolucao`),
    ),
    mountNewSidebarItems(
      'Turmas',
      ['fad', 'graduation-cap'],
      pathname === '/turmas',
      () => history.push(`/turmas`),
    ),
    mountNewSidebarItems(
      'Temas',
      ['fad', 'clipboard-list'],
      pathname === `/temas`,
      () => history.push(`/temas`),
    ),
    mountNewSidebarItems(
      'Grades',
      ['fad', 'clipboard-check'],
      pathname === '/grades',
      () => history.push(`/grades`),
    ),
    ...(shouldShowLibraryOnMenu
      ? [
          mountNewSidebarItems(
            'Biblioteca',
            ['fad', 'book-open'],
            pathname === '/biblioteca',
            () => history.push(`/biblioteca`),
          ),
        ]
      : []),
    ...(shouldShowSocioEmotionalOnMenu
      ? [
          mountNewSidebarItems(
            'Emocional',
            ['fad', 'face-smile-beam'],
            pathname === '/socio_emocional',
            () => history.push(`/socio_emocional`),
          ),
        ]
      : []),
    mountNewSidebarItems(
      'FAQ',
      ['fad', 'question-circle'],
      pathname === '/centraldeconhecimento',
      () => history.push(`/centraldeconhecimento`),
    ),
  ];

  const route = (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        return <Component {...props} />;
      }}
    />
  );

  return withPageWrapper ? (
    <PageWrapper
      topMenu={topSidebarItems}
      isMenuButtonDisabled={!isMobile}
      theme="dark"
      rightComponent={
        isLoadingCurrentUser ? (
          <FontAwesomeIcon icon={['fas', 'spinner']} spin />
        ) : (
          <SidebarRight
            name={`${user.get('first_name')} ${user.get('last_name')}`}
            email={user.get('email')}
          />
        )
      }
      userRole="teacher"
    >
      {route}
    </PageWrapper>
  ) : (
    route
  );
}

export default connect(
  (state: ApplicationState) => ({
    isDevAuthStateValid: isAuthed(state),
    isStudent: getIsStudent(state),
    isTeacher: isTeacher(state),
    isReviewer: isReviewer(state),
    isAdmin: isAdmin(state),
    terms: getTerms(state),
    isLoadingLogout: isLoadingLogout(state),
    user: getUser(state),
    userRole: getUserRoles(state).first(),
    isLoadingCurrentUser: isLoadingCurrentUser(state),
    unseenNotificationCount: getUnseenNotificationCount(state),
    notifications: getNotifications(state),
    featureFlags: getFeatureFlags(state),
    userProfile: getUserProfile(state),
  }),
  {
    fetchCurrentUserRequest,
    logoutUserRequest,
    fetchTermsRequest,
    logoutSuccess,
    clearState,
    fetchNotificationsRequest,
    fetchFeatureFlagRequest,
  },
)(NewAuthRoute);
